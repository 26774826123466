<template>
  <div>
    <template>
      <h4 class="not-margin" :style="{marginTop: '10px'}">
        <img src="../../../../assets/cancel.png" class="img-delete">
      </h4>
    </template>

    <div class="con-content" :style="{ marginTop: '5px' }">
      <p :style="{fontSize: '18px', color: '#195BFF'}">
        ກະລຸນາກົດ ຢືນຢັນ ເພື່ອຍົກເລີກການລັອກບິນນີ້
      </p>
      <p :style="{fontSize: '19px', color: '#e31919'}">
        {{ shipmentNumber }}
      </p>
    </div>

    <div class="field" :style="{marginTop: '20px'}">
      <div class="control">
        <label class="label">
          ຫມາຍເຫດ
          <span class="text-danger">* {{ errors.first('remark') }}</span>
        </label>
        <input class="input" name="remark" placeholder="ກະລຸນາປ້ອນຫມາຍເຫດ..." v-model="remark"
               v-validate="'required|max:100'">
      </div>
    </div>
    <template>
      <div class="con-footer" :style="{ marginTop: '10px' }">
        <vs-button :loading="loading" transparent :style="{color: 'crimson'}" @click="cancelItem()">
          ຢືນຢັນ
        </vs-button>
        <vs-button @click="$emit('close')" dark transparent>
          ຍົກເລີກ
        </vs-button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ['shipmentNumber'],

  data: () => ({
    loading: false,
    remark: 'ຍົກເລີກການບລັອກເຊັນຮັບ',
  }),
  methods: {
    cancelItem() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          this.$http.post('v1/auth/branches/shipments/orders/canceling/shipment-block-sign', {
            "bill_number": this.shipmentNumber,
            "remark": this.remark,
          }).then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$emit('close');
              this.$emit('success', true);
              this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'success', 3000);
            }, 300);
          }).catch(() => {
            this.$notification.OpenNotification_Error_Server('top-right', 'danger', 3000);
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    }
  }
}
</script>
<style lang="scss">

</style>
